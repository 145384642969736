"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'GROVEチケット',
    organizationId: 'IbScum38iY4T9UMX8dmM',
    version: '1.0',
    ionicAppId: '',
    applicationKey: 'grove',
    applicationType: 'organization',
    artistId: undefined,
    languages: ['ja', 'en'],
    ios: {
        bundleId: '',
        appId: '',
    },
    android: {
        bundleId: '',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: false,
            trade: false,
            membership: false,
            thread: false,
            prepaidPoint: {
                chargeable: false,
            },
            gacha: false,
            nft: false,
            liveStream: false,
            item: true,
            store: true,
            campaign: false,
            mission: false,
            resale: true,
            preSale: true,
            event: true,
            cart: true,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
        ],
        globalTabs: ['home', 'notifications', 'myProfile'],
        artistTabs: ['home', 'posts', 'store', 'events'],
    },
    deeplinkUrl: 'https://grove-ticket.utoniq.com',
    applink: 'grove-ticket.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/2299305',
    storagePath: 'grove',
    dynamicLinkPrefix: 'grove',
    deeplinkProd: 'grove-ticket.utoniq.com',
};
exports.default = appConfig;
